import React, { createRef, StyleHTMLAttributes } from "react";
import { Navigate, NavLink, redirect } from 'react-router-dom';
import { Component } from "react";
import BorderLayer from "../../assets/landing/img/ratios/550x650.jpg";
import Img404 from "../../assets/landing/img/error/404.jpg"
import BackButton from "../elements/backButton";
import ReactGA from "react-ga4";
import { withTranslation, WithTranslation } from "react-i18next";


interface Props extends WithTranslation {
 
}


type State = {

};

 class Project404 extends Component<Props, State> { 


  constructor(props: Props) {
    super(props);


    this.state = {

    };
  }





  componentDidMount() {

  }

  render() {

    const { } = this.state;

    const { t } = this.props


    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "project 404 not found" });

    return (

      <>

        <div className="arlo_tm_about" style={{marginBottom:"700px"}}>
          <div className="container">
            <div className="arlo_tm_main_title">
              <span>{t('project_not_found.section')}</span>
              <h3>{t('project_not_found.title')}</h3>
            </div>
            <div className="about_inner">
              {/*<div className="left">
                <div className="about_image_wrap parallax" data-relative-input="true">
                  <div className="image layer" data-depth="0.1">
                    <img src={BorderLayer} alt="" />
                    <div className="inner" data-img-url={Img404}></div>
                  </div>
                  <div className="border layer" data-depth="0.2">
                    <img src={BorderLayer} alt="" />
                    <div className="inner"></div>
                  </div>
                </div>
                </div>*/}
              <div className="right" style={{paddingLeft:"0px"}}>
                <div className="about_title">
                  <h3>{t('project_not_found.message')}</h3>
                </div>
                {/*<div className="about_short_contact_wrap">
						<ul>
							<li>
								<img className="svg" src="img/svg/gift.svg" alt="" />
								<span><label>Birthday:</label> 01.07.1990</span>
							</li>
							<li>
								<img className="svg" src="img/svg/calendar-4.svg" alt="" />
								<span><label>Age:</label> 30</span>
							</li>
							<li>
								<img className="svg" src="img/svg/location-1.svg" alt="" />
								<span><label>Location:</label> <a className="href_location" href="#">44 Place, Plano, USA</a></span>
							</li>
							<li>
								<img className="svg" src="img/svg/game-controller-2.svg" alt="" />
								<span><label>Interests:</label> Playstation, Reading</span>
							</li>
							<li>
								<img className="svg" src="img/svg/study-5.svg" alt="" />
								<span><label>Study:</label> <a className="href_location" href="#"> Univercity of Chicago</a></span>
							</li>
							<li>
								<img className="svg" src="img/svg/degree-1.svg" alt="" />
								<span><label>Degree:</label> Master</span>
							</li>	
							<li>
								<img className="svg" src="img/svg/message-3.svg" alt="" />
								<span><label>Mail:</label> <a href="mailto:example@gmail.com">mymail&#64;gmail.com</a></span>
							</li>
							<li>
								<img className="svg" src="img/svg/call-2.svg" alt="" />
								<span><label>Phone:</label> <a href="tel:+77 022 177 05 05">+77 022 177 05 05</a></span>
							</li>
						</ul>
           </div>*/}
                 <BackButton/>
              </div>
            </div>
          </div>
        </div>

      </>

    )
  }
}

export default withTranslation()(Project404)