import React from "react";
import { Component } from "react";
import { HashRouter, NavLink } from "react-router-dom";
import ActiveLink from "./elements/activeLink";
import { GetLocation } from "./elements/getLocation";
import Navbars from "./elements/navBars";


type Props = {};

type State = {
  open:boolean
};

export default class Navbar extends Component<Props, State> {

  constructor(props:Props) {
    super(props);

    

    this.state = {
		open:false
    }
  }

  openMobileMenù = () =>{
     this.setState({
		open: !this.state.open
	 })
  }

  componentDidMount(){

    
  }

  render(){


    return (
      <div>
          <Navbars/>
      </div> 
   
    )
  }
}