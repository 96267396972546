import axios from "axios";
import jwt_decode from "jwt-decode";
import { api } from "../../settingApi";

const VERSION = "v1";

class HomeService {
  getSettings() {
    return api({
      method: "get",
      url: `${VERSION}/page/home`,
    });
  }

  updateSettings(
    id: number,
    name:string,
    surname:string,
    urlvideo:string
  ) {
    return api({
      method: "patch",
      url: `${VERSION}/page/home/${id}`,
      data: {
        name,
        surname,
        urlvideo
      },
    });
  }

  getImage(id:number) {
    return api({
      method: 'get',
      url: `${VERSION}/page/home/image/${id}`,
      responseType: 'blob'
    })
    
  }

  updateImage(image,id) {
    const formData = new FormData();
    formData.append("file", image);
  
    if(id == undefined){
      id = ''
    }
  
    return api({
      method: 'patch',
      url: `${VERSION}/page/home/image/${id}`,
      data:formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    
  }
}



export default new HomeService();
