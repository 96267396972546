import React from "react";
import { Component } from "react";
import Hero from "./common/hero";
import About from "./aboutme";
import Services from "./services";
import Portfolio from "./portfolio";
import Counter from "./counter";
import News from "./news";
import Contact from "./contact";
import pageService from "../api/page/page.service";
import { Navigate, NavigationType } from "react-router-dom";
import $ from "jquery";
import ReactGA from "react-ga4";

type Props = {};

type State = {
  message: string;
  successful: boolean;
  updateid: number;
  route:string,
  home: boolean;
  aboutme: boolean;
  services: boolean;
  portfolio: boolean;
  statistics: boolean;
  news: boolean;
  contactme: boolean;
};


export default class Index extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    

    this.state = {
      message: "",
      successful: false,
      updateid: 1,
      route:'',
      home:true,
      aboutme:true,
      services:true,
      portfolio:true,
      statistics:true,
      news:true,
      contactme:true
    };

    ReactGA.send({ hitType: "pageview", page: "/", title: "home page" });
  
  }


  getSettings = async () => {
    pageService.getSettings().then(
      (response) => {

     if(response.data[0]){
        this.setState({
          home: response.data[0].home,
          aboutme: response.data[0].aboutme,
          services: response.data[0].services,
          portfolio: response.data[0].portfolio,
          statistics: response.data[0].statistics,
          news: response.data[0].news,
          contactme: response.data[0].contactme
        });
     }

    
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  };

  componentDidMount(): void {
    this.getSettings()
  }

  render(){

    const { } = this.state;

    return (

     <>
      
     {this.state.route != '' ? <Navigate to={this.state.route}/> : null}

     {this.state.home ? <div onMouseEnter={() =>this.setState({route:'#home'})}><Hero/></div> : null}
     {this.state.aboutme ? <div onMouseEnter={() =>this.setState({route:'#about'})}><About/></div> : null}
     {this.state.services ? <div onMouseEnter={() =>this.setState({route:'#services'})}><Services/></div> : null}
     {this.state.portfolio ? <div onMouseEnter={() =>this.setState({route:'#portfolio'})}><Portfolio/></div> : null}
     {this.state.statistics ? <Counter/> : null}
     {this.state.news ? <div onMouseEnter={() =>this.setState({route:'#news'})}><News/></div> : null}
     {this.state.contactme ? <div onMouseEnter={() =>this.setState({route:'#contact'})}><Contact/></div> : null}
          
     </>

    )
  }
}