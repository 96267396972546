import React from "react";
import { Component } from "react";
import Navbar from "./navBar";
import Footer from "./footer";
import { Outlet } from "react-router-dom";

type Props = {};

type State = {};

export default class AppLayout extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {

    const description_wrap : React.CSSProperties = {
       position:'relative',
       width: '100%',
       height: '100%',
       float: 'left',
       overflow: 'hidden',
       padding: '50px',
       overflowY: 'scroll'
    }

    return (
      <div>
        {/*<div className="arlo_tm_preloader">
          <div className="spinner_wrap">
            <div className="spinner"></div>
          </div>
         </div>*/}
        <div
          className="arlo_tm_all_wrap"
          data-magic-cursor=""
          data-color="crimson"
        >
          <Navbar />
          <Outlet/>
          <Footer />
        </div>
      </div>
    );
  }
}
