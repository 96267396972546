import axios from "axios";
import jwt_decode from "jwt-decode";
import { api } from "../settingApi"

const VERSION = "v1";

class StatisticsService {

  getSettings() {
    return api({
      method: "get",
      url: `${VERSION}/page/statistics`,
    });
  }

  updateSettings(
    id: number,
    customers:string,
    projects:string,
    ongoing:string,
    experience:string

  ) {
    return api({
      method: "patch",
      url: `${VERSION}/page/statistics/${id}`,
      data: {
        customers,
        projects,
        ongoing,
        experience
      },
    });
     
  }

  
}



export default new StatisticsService();