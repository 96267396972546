import React from "react";
import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";


interface Props extends WithTranslation {
 
}

type State = {
  
};

 class Footer extends Component<Props, State> {

  constructor(props:Props) {
    super(props);

    

    this.state = {
    }
  }

  componentDidMount(){
    
  }

  render(){

	const { t } = this.props

    return (
      <div>
         
	<div className="arlo_tm_section">
		<div className="arlo_tm_copyright">
			<div className="container">
				<div className="inner">
					<div className="copy wow fadeInLeft" data-wow-duration="1.5s">
						<p>{t('footer.text')}</p>
						<a href="https://www.iubenda.com/privacy-policy/16603589" className="iubenda-white iubenda-noiframe  iubenda-noiframe " title="Privacy Policy " style={{color: "white"}}>Privacy Policy</a>
						<div style={{marginLeft:"10px",display:"inline-block"}}><a href="https://www.iubenda.com/privacy-policy/16603589/cookie-policy" className="iubenda-white iubenda-noiframe  iubenda-noiframe " title="Cookie Policy " style={{color: "white",backgroundColor:"black"}}>Cookie Policy</a></div>
					</div>
					<div className="social wow fadeInLeft" data-wow-duration="1.5s" data-wow-delay=".2s">
						{/*<ul>
							<li><a href="#"><img className="svg" src="img/svg/social/facebook.svg" alt="" /></a></li>
							<li><a href="#"><img className="svg" src="img/svg/social/twitter.svg" alt="" /></a></li>
							<li><a href="#"><img className="svg" src="img/svg/social/behance.svg" alt="" /></a></li>
							<li><a href="#"><img className="svg" src="img/svg/social/dribbble.svg" alt="" /></a></li>
							<li><a href="#"><img className="svg" src="img/svg/social/tik-tok.svg" alt="" /></a></li>
	                    </ul>*/}
					</div>
				</div>
			</div>
		</div>
	</div>
	
	<div className="mouse-cursor cursor-outer"></div>
	<div className="mouse-cursor cursor-inner"></div>
	
      </div> 
   
    )
  }
}

export default withTranslation()(Footer)