import React, { useEffect, useState } from "react";
import homeService from "../../api/page/home/home.service";
import { motion } from "framer-motion";
import "../../assets/landing/css/loadingImg.css"


const HomeImage = ({ id }) => {
  const [preview, setPreview] = useState<any>()

    const [imageLoading, setImageLoading] = useState(true);
    const [pulsing, setPulsing] = useState(true);

    const imageLoaded = () => {
        
    };


  useEffect(() => {

    homeService.getImage(
      id
    ).then(
      response => {

        const reader = new FileReader()
        reader.readAsDataURL(response.data)
        reader.onload = () => {
          setPreview(reader.result)
        }

        setImageLoading(false);
        setTimeout(() => setPulsing(false), 600);

      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

      }

    );

  }, [null]);





  return (
    <>
      <div className="image_wrap">
        <div   className={`${pulsing ? "pulse" : ""} loadable`}>
          <motion.div 
            initial={{ height:  "130px",opacity: 0 }}
            animate={{
                height: imageLoading ? "130px" : "auto",
                opacity: imageLoading ? 0 : 1
            }}
            transition={{
                height: { delay: 0, duration: 0.4 },
                opacity: { delay: 0.5, duration: 0.4 }
            }}
            className="main" 
            data-img-url={preview}
            style={{ backgroundImage: `url(${preview})` }}>
            </motion.div>
        </div>
      </div>
    </>


  )
}

export default HomeImage