import axios from "axios";
import jwt_decode from "jwt-decode";
import { api } from "../../settingApi";

const VERSION = "v1";

class AboutMeService {
  getSettings() {
    return api({
      method: "get",
      url: `${VERSION}/page/aboutme`,
    });
  }

  updateSettings(
    id: number,
    description: string,
    birthdayinfo: boolean,
    birthday: string,
    ageinfo: boolean,
    age: string,
    locationinfo: boolean,
    location: string,
    interestsinfo: boolean,
    interests: string,
    studyinfo: boolean,
    study: string,
    degreeinfo: boolean,
    degree: string,
    emailinfo: boolean,
    email: string,
    phoneinfo: boolean,
    phone: string,
    downloadcv:boolean
  ) {
    return api({
      method: "patch",
      url: `${VERSION}/page/aboutme/${id}`,
      data: {
        description,
        birthdayinfo,
        birthday ,
        ageinfo,
        age,
        locationinfo,
        location,
        interestsinfo,
        interests,
        studyinfo,
        study,
        degreeinfo,
        degree,
        emailinfo,
        email,
        phoneinfo,
        phone,
        downloadcv
      },
    });
  }

  getImage(id:number) {
    return api({
      method: 'get',
      url: `${VERSION}/page/aboutme/image/${id}`,
      responseType: 'blob'
    })
    
  }

  updateImage(image,id) {
    const formData = new FormData();
    formData.append("file", image);
  
    if(id == undefined){
      id = ''
    }
  
    return api({
      method: 'patch',
      url: `${VERSION}/page/aboutme/image/${id}`,
      data:formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    
  }

  getCurriculum(id:number) {
    return api({
      method: 'get',
      url: `${VERSION}/page/aboutme/curriculum/${id}`,
      responseType: 'blob'
    })
    
  }

  updateCurriculum(cv,id) {
    const formData = new FormData();
    formData.append("file", cv);
  
    if(id == undefined){
      id = ''
    }
  
    return api({
      method: 'patch',
      url: `${VERSION}/page/aboutme/curriculum/${id}`,
      data:formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    
  }
}



export default new AboutMeService();
