import React from "react";
import { Component } from "react";
import WebDevelopment from "../assets/landing/img/svg/code.svg"
import CreativeDesign from "../assets/landing/img/svg/creativity.svg"
import { WithTranslation, withTranslation } from "react-i18next";


interface Props extends WithTranslation {
 
}

type State = {
 
};

 class Services extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    

    this.state = {
      
    };
  }

 

  render(){

    const { } = this.state;

	const { t } = this.props


    return (

     <>

	<div className="arlo_tm_section" id="services">
		<div className="arlo_tm_services">
			<div className="container">
				<div className="arlo_tm_main_title">
					<span>{t('services.section')}</span>
					<h3>{t('services.title')}</h3>
				</div>
				<div className="service_inner">
					<ul>
						<li className="wow fadeInLeft" data-wow-duration="1.5s">
							<div className="inner">
								<img className="svg" src={CreativeDesign} alt="" />
								<h3>{t('services.first_service_title')}</h3>
								<p>{t('services.first_service_description')}</p>
							</div>
						</li>
						<li className="wow fadeInLeft" data-wow-duration="1.5s" data-wow-delay="0.2s">
							<div className="inner">
								<img className="svg" src={WebDevelopment} alt="" />
								<h3>{t('services.second_service_title')}</h3>
								<p>{t('services.second_service_description')}</p>
							</div>
						</li>
					</ul>
					<div className="lets_work wow fadeInLeft" data-wow-duration="1.5s">
						<p>{t('services.text')} <a href="#contact"> {t('services.text_link')}</a></p>
					</div>
				</div>
			</div>
		</div>
	</div>

          
     </>

    )
  }
}

export default withTranslation()(Services)