import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import ProjectImage from "./projectImage";
import { Pagination } from "antd";
import portfolioService from "../../api/page/portfolio/portfolio.service";
import { useTranslation } from "react-i18next";

const Projects = () => {
    const [projects, setProjects] = useState<any[]>([])
    const [total, setTotal] = useState<any>()
    const [page, setPage] = useState<any>(1)
    const [elementPerPage, setElementPerPage] = useState<any>(3)
    const { t, i18n } = useTranslation();

    useEffect(() => {
        getProjects()
    }, [null]);

    async function getProjects() {

        portfolioService.findAll(
        ).then(
            response => {

                setProjects(response.data)
                setTotal(response.data.length)
            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

            }


        );

    }

    const indexOfLastPage = page + elementPerPage - 1
    const indexOfFirstPage = indexOfLastPage - elementPerPage
    const currentElement = projects?.slice(indexOfFirstPage, indexOfLastPage)


    const onShowSizeChange = (current, pageSize) => {
        setElementPerPage(pageSize)
    }


    return (
        <>

            <div className="arlo_tm_section" id="portfolio">
                <div className="arlo_tm_portfolio">
                    <div className="container">
                        <div className="arlo_tm_main_title">
                            <span>{t('portfolio.section')}</span>
                            <h3>{t('portfolio.title')}</h3>
                        </div>
                        <div className="portfolio_inner">



                            <div className="portfolio_list">
                                {projects.length == 0 ? <p style={{ display: "inline-block" }}>{t('portfolio.no_content')}</p> :
                                    <>
                                        {currentElement!.map((item, index) => {
                                            return (
                                                <ul className="gallery_zoom" key={index}>
                                                    <li className="webdevelopment">
                                                        <div>
                                                            <div className="inner">
                                                                <div className="entry arlo_tm_portfolio_animation_wrap" data-title="Sweet Fruit" data-category="Web development">
                                                                    <ProjectImage id={item.id} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            )
                                        })}

                                    </>}
                            </div>
                            {projects.length == 0 ? null :
                                <Pagination
                                    onChange={(value) => setPage(value)}
                                    pageSize={elementPerPage}
                                    total={total}
                                    current={page}
                                    showSizeChanger
                                    showQuickJumper
                                    onShowSizeChange={onShowSizeChange}
                                    showTotal={total > 1 ? (total) => ` ${total} ${t('portfolio.multiple_elements')}` : (total) => ` ${total} ${t('portfolio.one_element')}`}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>


    )
}

export default Projects