import React, { useEffect, useState } from "react";
import Ratios from '../../assets/landing/img/ratios/ratio-4-3.jpg'
import newsService from "../../api/news/news.service";


const NewsImagePreview = ({ id }) => {
    const [preview, setPreview] = useState<any>()


    useEffect(() => {
        
        newsService.getImage(
            id
        ).then(
            response => {

                const reader = new FileReader()
                reader.readAsDataURL(response.data)
                reader.onload = () => {
                    setPreview(reader.result)
                }

            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

            }

        );

    }, [id]);




    return (
        <>
            <div className="image">
                <img src={Ratios} alt="" />
                <div className="main" data-img-url="img/news/1.jpg" style={{ backgroundImage: `url(${preview})` }}></div>
                <a className="arlo_tm_full_link" href={'javascript:'}></a>
            </div>
        </>


    )
}

export default NewsImagePreview