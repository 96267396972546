import axios from "axios";
import jwt_decode from "jwt-decode";
import { api } from "../settingApi";

const VERSION = "v1";

class NewsService {

  getNews() {
    return api({
      method: "get",
      url: `${VERSION}/page/news`,
    });
  }


   create(title:string,author:string,date:string,text:string,highlight:boolean,texthighlighted:string,sharing:boolean){
    return api({
        method: "post",
        url: `${VERSION}/page/news`,
        data: {
          title,
          author,
          date,
          text,
          highlight,
          texthighlighted,
          sharing
        },
      });
   }

   createImage(image,id) {
    const formData = new FormData();
    formData.append("file", image);
  
    if(id == undefined){
      id = ''
    }
  
    return api({
      method: 'post',
      url: `${VERSION}/page/news/image/${id}`,
      data:formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    
  }

  update(id: number,title:string,author:string,date:string,text:string,highlight:boolean,texthighlighted:string,sharing:boolean) {
    return api({
      method: "patch",
      url: `${VERSION}/page/news/${id}`,
      data: {
        title,
        author,
        date,
        text,
        highlight,
        texthighlighted,
        sharing
      },
    });
  }

  getImage(id:number) {
    return api({
      method: 'get',
      url: `${VERSION}/page/news/image/${id}`,
      responseType: 'blob'
    })
    
  }

  updateImage(image,id) {
    const formData = new FormData();
    formData.append("file", image);
  
    if(id == undefined){
      id = ''
    }
  
    return api({
      method: 'patch',
      url: `${VERSION}/page/news/image/${id}`,
      data:formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    
  }

  delete(id:number) {
    return api({
      method: 'delete',
      url: `${VERSION}/page/news/${id}`,
    })
    
  }
}



export default new NewsService();
