import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const ActiveLink = ({children,path}) => {
  
    const location = useLocation();
    const [active,setActive] = useState('')

    useEffect(() =>{
       if(location.hash == path){
       setActive('current')
       }else{
        setActive('')
       }
    })
    
    

    return (
        <li className={active}>{children}</li>
    )
}

export default ActiveLink