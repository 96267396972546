import React from "react";
import { Component } from "react";
import homeService from "../../api/page/home/home.service";
import HomeImage from "../elements/homeImage";
import Develop from"../../assets/landing/img/hero/develop.jpg"

type Props = {};

type State = {
	message: string;
	successful: boolean;
	id: number;
	loading: boolean;
	videoData:string,
	file: string;
	name:string,
	surname:string,
	urlvideo:string
  };
export default class Hero extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    

    this.state = {
		message: "",
		successful: false,
		id: 1,
		loading: true,
		videoData:"",
		file: "",
		name:"",
		surname:"",
		urlvideo:""
    };
  }

  //"{videoURL:'https://www.youtube.com/watch?v=iIrSCm_0Sj4',containment:'#home', showControls:false, autoPlay:true, loop:true, mute:true, startAt:0, opacity:1, quality:'default'}"


  getSettings = async () => {
    homeService.getSettings().then(
      (response) => {
	
	  if(response.data[0]){
        this.setState({
		  id: response.data[0].id,
		  name: response.data[0].name,
		  surname: response.data[0].surname,
        });
	  }

        
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  };

  componentDidMount(){
	this.getSettings()
  }
  

  render(){

    var { videoData } = this.state;

	
    return (

     <>
        
       
	<div className="arlo_tm_hero" id="home">
	    <div className="background">
		    <div className="image" data-img-url={Develop}  style={{ backgroundImage: `url(${Develop})` }}></div>
			<div className="overlay"></div>
	    </div>
		<div className="content">
			<HomeImage id={this.state.id}/>
			<div className="name_holder">
				<h3>{this.state.name}<span> {this.state.surname}</span></h3>
			</div>
			<div className="text_typing">
				<p>I'm a <span className="arlo_tm_animation_text_word">Developer</span></p>
			</div>
		</div>
		<div className="arlo_tm_arrow_wrap bounce anchor">
			<a href="#about"><i className="xcon-angle-double-down"></i></a>
		</div>
	</div>
	
          
     </>

    )
  }
}