import React from "react";
import { Component } from "react";
import portfolioService from "../api/page/portfolio/portfolio.service";
import ProjectImage from "./elements/projectImage";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import Projects from "./elements/projects";

type Props = {};

type State = {
};

export default class Portfolio extends Component<Props, State> {

	constructor(props: Props) {
		super(props);


		this.state = {
		
		};
	}


	componentDidMount() {

		jQuery(window).on('scroll', function () {
			var menu = jQuery('.arlo_tm_topbar');
			var WinOffset = jQuery(window).scrollTop();

			if (WinOffset! >= 100) {
				menu.addClass('animate');
			} else {
				menu.removeClass('animate');
			}
		});
	}

	render() {

		const { } = this.state;


		return (

			<>
               <Projects/>
			</>

		)
	}
}