import React, { useEffect, useState } from "react";
import aboutmeService from "../../api/page/aboutme/aboutme.service";
import BorderLayer from "../../assets/landing/img/ratios/550x650.jpg";
import AboutImg from "../../assets/landing/img/about/1.jpg";
import LoadingImage from "../animations/loadingImage";


const AboutMeImage = ({ id }) => {
  const [preview, setPreview] = useState<any>()
  const [pass,setPass] = useState<boolean>(false)


  useEffect(() => {

    aboutmeService.getImage(
      id
    ).then(
      response => {

        const reader = new FileReader()
        reader.readAsDataURL(response.data)
        reader.onload = () => {
          setPreview(reader.result)
        }

        setPass(true)

      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

      }

    );
  }, [null]);





  return (
    <>
      <div className="left">
        <div
          className="about_image_wrap parallax"
          data-relative-input="true"
        >
          <div className="image layer" data-depth="0.1">
            <img src={BorderLayer} alt="" />
            <LoadingImage preview={preview} className={'inner'} style={{ background: "#ccc" }}  padding={"1.25rem"} initialHeight={'32rem'} imageLoad={pass ? false : null} pulse={pass ? false : null}/>
            {/*<div className="inner" data-img-url={AboutImg} style={{ backgroundImage: `url(${preview})` }}></div>*/}
          </div>
          <div className="border layer" data-depth="0.2">
            <img src={BorderLayer} alt="" />
          </div>
        </div>
      </div>
    </>


  )
}

export default AboutMeImage