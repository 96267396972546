import React from "react";
import { Component } from "react";
import Birthday from "../assets/landing/img/svg/gift.svg";
import Age from "../assets/landing/img/svg/calendar-4.svg";
import Location from "../assets/landing/img/svg/location-1.svg";
import Interests from "../assets/landing/img/svg/game-controller-2.svg";
import Study from "../assets/landing/img/svg/study-5.svg";
import Degree from "../assets/landing/img/svg/degree-1.svg";
import Mail from "../assets/landing/img/svg/message-3.svg";
import Phone from "../assets/landing/img/svg/call-2.svg";
import aboutmeService from "../api/page/aboutme/aboutme.service";
import AboutMeImage from "./elements/aboutmeImage";
import DownloadCV from "./elements/downloadCv";
import { WithTranslation, withTranslation } from "react-i18next";

interface Props extends WithTranslation {
 
}

type State = {
  message: string;
  successful: boolean;
  id: number;
  loading: boolean;
  file: string;
  description: string;
  birthdayinfo: boolean;
  birthday: string;
  ageinfo: boolean;
  age: string;
  locationinfo: boolean;
  location: string;
  interestsinfo: boolean;
  interests: string;
  studyinfo: boolean;
  study: string;
  degreeinfo: boolean;
  degree: string;
  emailinfo: boolean;
  email: string;
  phoneinfo: boolean;
  phone: string;
  downloadcv: boolean
};



class About extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      message: "",
      successful: false,
      id: 1,
      loading: true,
      file: "",
      description: "",
      birthdayinfo: false,
      birthday: "",
      ageinfo: false,
      age: "",
      locationinfo: false,
      location: "",
      interestsinfo: false,
      interests: "",
      studyinfo: false,
      study: "",
      degreeinfo: false,
      degree: "",
      emailinfo: false,
      email: "",
      phoneinfo: false,
      phone: "",
      downloadcv: false
    };
  }

  getSettings = async () => {
    aboutmeService.getSettings().then(
      (response) => {

        if (response.data[0]) {
          this.setState({
            id: response.data[0].id,
            description: response.data[0].description,
            birthdayinfo: response.data[0].birthdayinfo,
            birthday: response.data[0].birthday,
            ageinfo: response.data[0].ageinfo,
            age: response.data[0].age,
            locationinfo: response.data[0].locationinfo,
            location: response.data[0].location,
            interestsinfo: response.data[0].interestsinfo,
            interests: response.data[0].interests,
            studyinfo: response.data[0].studyinfo,
            study: response.data[0].study,
            degreeinfo: response.data[0].degreeinfo,
            degree: response.data[0].degree,
            emailinfo: response.data[0].emailinfo,
            email: response.data[0].email,
            phoneinfo: response.data[0].phoneinfo,
            phone: response.data[0].phone,
            downloadcv: response.data[0].downloadcv
          });
        }

      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  };

  componentDidMount() {
    this.getSettings()
  }

  render() {
    const { } = this.state;

    const { t } = this.props

    return (
      <>
        <div className="arlo_tm_about" id="about">
          <div className="container">
            <div className="arlo_tm_main_title">
              <span>{t('aboutme.section')}</span>
              <h3>{t('aboutme.title')}</h3>
            </div>
            <div className="about_inner">
              <AboutMeImage id={this.state.id} />
              <div className="right">
                <div className="about_title">
                  <h3>
                  {t('aboutme.presentation')} Gabriele Barreca {t('aboutme.presentation_second')}{" "}
                    <span className="arlo_tm_animation_text_word">Developer</span>
                  </h3>
                </div>
                <div className="text">
                  <p>
                    {this.state.description}
                  </p>
                </div>
                <div className="about_short_contact_wrap">
                  <ul>
                    {this.state.birthdayinfo ? <li>
                      <img className="svg" src={Birthday} alt="" />
                      <span>
                        <label>{t('aboutme.birthday')}</label> {this.state.birthday}
                      </span>
                    </li> : null}
                    {this.state.ageinfo ? <li>
                      <img className="svg" src={Age} alt="" />
                      <span>
                        <label>{t('aboutme.age')}</label> {this.state.age}
                      </span>
                    </li> : null}
                    {this.state.locationinfo ? <li>
                      <img className="svg" src={Location} alt="" />
                      <span>
                        <label>{t('aboutme.location')}:</label>{" "}
                        <a className="href_location" href="#">
                          {this.state.location}
                        </a>
                      </span>
                    </li> : null}
                    {this.state.interestsinfo ? <li>
                      <img className="svg" src={Interests} alt="" />
                      <span>
                        <label>{t('aboutme.interests')}</label> {this.state.interests}
                      </span>
                    </li> : null}
                    {this.state.studyinfo ? <li>
                      <img className="svg" src={Study} alt="" />
                      <span>
                        <label>{t('aboutme.study')}</label>{" "}
                        <a className="href_location" href="#">
                          {" "}
                          {this.state.study}
                        </a>
                      </span>
                    </li> : null}
                    {this.state.degreeinfo ? <li>
                      <img className="svg" src={Degree} alt="" />
                      <span>
                        <label>{t('aboutme.degree')}</label> {this.state.degree}
                      </span>
                    </li> : null}
                    {this.state.emailinfo ? <li>
                      <img className="svg" src={Mail} alt="" />
                      <span>
                        <label>{t('aboutme.mail')}</label>{" "}
                        <a href={`mailto:${this.state.email}`}>
                          {this.state.email}
                        </a>
                      </span>
                    </li> : null}
                    {this.state.phoneinfo ? <li>
                      <img className="svg" src={Phone} alt="" />
                      <span>
                        <label>{t('aboutme.phone')}</label>{" "}
                        <a href={`tel:${this.state.phone}`}>{this.state.phone}</a>
                      </span>
                    </li> : null}
                  </ul>
                </div>
                {this.state.downloadcv ?
                  <DownloadCV id={this.state.id} /> : null}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(About)