import React from "react";
import { Component } from "react";
import * as yup from "yup";
import messageService from "../api/message/message.service";
import { Field, Form, Formik } from "formik";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import ReactGA from "react-ga4";
import { WithTranslation, withTranslation } from "react-i18next";

interface Props extends WithTranslation {
 
}

type State = {
  successful: boolean;
  message: string;
  sitekey: string;
};

class Contact extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      successful: false,
      message: "",
      sitekey: "",
    };
  }

  validationSchema() {
    const emailRules =
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
    return yup.object().shape({
      hcaptcha: yup.string().required("This field is required"),
      name: yup.string().required("Enter a name"),
      surname: yup.string().required("Enter a surname"),
      email: yup
        .string()
        .required("Enter an email")
        .matches(emailRules, "Enter a valid email"),
      subject: yup.string().required("Enter an object"),
      body: yup.string().required("Enter a message body"),
    });
  }

  sendMessage = async (formValue: {
    hcaptcha: string;
    name: string;
    surname: string;
    email: string;
    subject: string;
    body: string;
  }) => {
    const { hcaptcha, name, surname, email, subject, body } = formValue;

    this.setState({
      message: "",
      successful: false,
    });

    messageService
      .createMessage(hcaptcha, name, surname, email, subject, body)
      .then(
        (response) => {
          this.setState({
            successful: true,
          });

          ReactGA.event({
            category: "send message",
            action: "send message",
          });

        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
  };

  componentDidMount() {
    this.setState({ sitekey: `${process.env.REACT_APP_HCAPTCHA_TOKEN}` });
  }

  render() {
    const { successful, message } = this.state;

    const { t } = this.props

    const initialValues = {
      hcaptcha: "",
      name: "",
      surname: "",
      email: "",
      subject: "",
      body: "",
    };

    return (
      <>
        <div className="arlo_tm_section" id="contact">
          <div className="arlo_tm_contact">
            <div className="container">
              <div className="contact_inner">
                <div className="arlo_tm_main_title">
                  <span>{t('contact.section')}</span>
                  <h3>{t('contact.title')}</h3>
                </div>
                <div className="desc">
                  <p className="wow txt" data-splitting>
                  {t('contact.warning')}
                  </p>
                </div>
                <div className="wrapper">
                  <div className="left wow fadeInLeft" data-wow-duration="1.5s">
                    <div className="fields">
                      <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validationSchema={this.validationSchema}
                        onSubmit={this.sendMessage}
                      >
                        {(formik) => (
                          <Form className="contact_form" id="contact_form">
                            {successful && (
                              <div className="form-group">
                                <div className="returnmessage">
                                  Your message has been received, We will
                                  contact you soon.
                                </div>
                              </div>
                            )}
                            <div className="empty_notice">
                              <span>Please Fill Required Fields</span>
                            </div>
                            <div className="first">
                              <ul>
                                <li>
                                  <Field
                                    name="name"
                                    type="text"
                                    placeholder={t('contact.form.name')}
                                    className={
                                      formik.touched.name && formik.errors.name
                                        ? "form-control ps-5 is-invalid "
                                        : "form-control ps-5"
                                    }
                                  />
                                  {formik.touched.name && formik.errors.name ? (
                                    <div
                                      className="invalid-feedback danger"
                                      style={{ color: "#F52225" }}
                                    >
                                      {t('contact.form.error_name')}
                                    </div>
                                  ) : null}
                                </li>
                                <li>
                                  <Field
                                    name="surname"
                                    type="text"
                                    placeholder={t('contact.form.surname')}
                                    className={
                                      formik.touched.surname &&
                                      formik.errors.surname
                                        ? "form-control ps-5 is-invalid "
                                        : "form-control ps-5"
                                    }
                                  />
                                  {formik.touched.surname &&
                                  formik.errors.surname ? (
                                    <div
                                      className="invalid-feedback danger"
                                      style={{ color: "#F52225" }}
                                    >
                                      {t('contact.form.error_surname')}
                                    </div>
                                  ) : null}
                                </li>
                                <li>
                                  <Field
                                    name="email"
                                    type="text"
                                    placeholder={t('contact.form.email')}
                                    className={
                                      formik.touched.email &&
                                      formik.errors.email
                                        ? "form-control ps-5 is-invalid "
                                        : "form-control ps-5"
                                    }
                                  />
                                  {formik.touched.email &&
                                  formik.errors.email ? (
                                    <div
                                      className="invalid-feedback danger"
                                      style={{ color: "#F52225" }}
                                    >
                                      {t('contact.form.error_email')}
                                    </div>
                                  ) : null}
                                </li>
                                <li>
                                  <Field
                                    name="subject"
                                    type="text"
                                    placeholder={t('contact.form.subject')}
                                    className={
                                      formik.touched.subject &&
                                      formik.errors.subject
                                        ? "form-control ps-5 is-invalid "
                                        : "form-control ps-5"
                                    }
                                  />
                                  {formik.touched.subject &&
                                  formik.errors.subject ? (
                                    <div
                                      className="invalid-feedback danger"
                                      style={{ color: "#F52225" }}
                                    >
                                      {t('contact.form.error_subject')}
                                    </div>
                                  ) : null}
                                </li>
                              </ul>
                            </div>
                            <div className="last">
                              <Field
                                name="body"
                                component="textarea"
                                rows="4"
                                placeholder={t('contact.form.message')}
                                className={
                                  formik.touched.body && formik.errors.body
                                    ? "form-control ps-5 is-invalid "
                                    : "form-control ps-5"
                                }
                              />
                              {formik.touched.body && formik.errors.body ? (
                                <div
                                  className="invalid-feedback danger"
                                  style={{
                                    color: "#F52225",
                                    marginBottom: "20px",
                                  }}
                                >
                                  {t('contact.form.message')}
                                </div>
                              ) : null}
                              <HCaptcha
                                sitekey={this.state.sitekey}
                                onVerify={(event) => {
                                  formik.setFieldValue("hcaptcha", event);
                                }}
                              />
                              {formik.touched.hcaptcha &&
                              formik.errors.hcaptcha ? (
                                <div
                                  className="invalid-feedback"
                                  style={{
                                    color: "#F52225",
                                    marginBottom: "20px",
                                  }}
                                >
                                  {t('contact.form.error_hcaptcha')}
                                </div>
                              ) : null}
                            </div>
                            <div className="arlo_tm_button" data-color="dark">
                              <button>{t('contact.form.send_button')}</button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                  <div
                    className="right wow fadeInLeft"
                    data-wow-duration="1.5s"
                    data-wow-delay=".2s"
                  >
                    <div className="map_wrap">
                      <div className="map" id="ieatmaps"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(Contact)