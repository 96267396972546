// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
    font-family: sans-serif;
    text-align: center;
  }
  
  .pulse {
    animation: pulse 1.4s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
  .loadable {
    border-radius: 1.25rem;
    overflow: hidden;
    margin: 0 auto;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  
  
  @keyframes pulse {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.3;
    }
  }`, "",{"version":3,"sources":["webpack://./src/assets/landing/css/loadingImg.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,kBAAkB;EACpB;;EAEA;IACE,2DAA2D;EAC7D;EACA;IACE,sBAAsB;IACtB,gBAAgB;IAChB,cAAc;IACd;wCACoC;EACtC;;;EAGA;IACE;;MAEE,UAAU;IACZ;IACA;MACE,YAAY;IACd;EACF","sourcesContent":[".App {\n    font-family: sans-serif;\n    text-align: center;\n  }\n  \n  .pulse {\n    animation: pulse 1.4s cubic-bezier(0.4, 0, 0.6, 1) infinite;\n  }\n  .loadable {\n    border-radius: 1.25rem;\n    overflow: hidden;\n    margin: 0 auto;\n    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),\n      0 2px 4px -1px rgba(0, 0, 0, 0.06);\n  }\n  \n  \n  @keyframes pulse {\n    0%,\n    100% {\n      opacity: 1;\n    }\n    50% {\n      opacity: 0.3;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
