import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";


const BackButton  = ({}) => {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation();



    return (
        <>
            <div className="arlo_tm_button">
                <a onClick={(e) => { e.preventDefault(); navigate(-1);}} href=""><span>{t('page_not_found.goback_button')}</span></a>
            </div>
        </>

    )
}

export default BackButton