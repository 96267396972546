import React, { useEffect, useState } from "react";
import aboutmeService from "../../api/page/aboutme/aboutme.service";
import ReactGA from "react-ga4";
import { Skeleton } from "antd";
import { useTranslation } from "react-i18next";

const DownloadCV = ({ id }) => {
    const [preview, setPreview] = useState<any>()
    const [loading, setLoading] = useState<any>(true)
    const { t, i18n } = useTranslation();


    useEffect(() => {

        aboutmeService.getCurriculum(
            id
        ).then(
            response => {

                const reader = new FileReader()
                reader.readAsDataURL(response.data)
                reader.onload = () => {
                    setPreview(reader.result)
                }

                setLoading(false)

            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

            }

        );

    }, [null]);





    return (
        <>
            <div className="arlo_tm_button">
                {loading ? <Skeleton.Button active style={{width:"204px",height:"57px"}}/> :
                    <a href={preview} download="Gabriele Barreca curriculum" onClick={() => [ReactGA.event({ category: "download cv", action: "download cv" })]}>
                        <span>{t('aboutme.download_button')}</span>
                    </a>
                }
            </div>
        </>


    )
}

export default DownloadCV