import axios from "axios";
import jwt_decode from "jwt-decode";
import { api } from "../settingApi";

const VERSION = "v1";

class PageService {

  getSettings() {
    return api({
      method: "get",
      url: `${VERSION}/page/general`,
    });
  }

  updateSettings(
    id: number,
    home:boolean,
    aboutme:boolean,
    services:boolean,
    portfolio:boolean,
    statistics:boolean,
    news:boolean,
    contactme:boolean

  ) {
    return api({
      method: "patch",
      url: `${VERSION}/page/general/${id}`,
      data: {
        home,
        aboutme,
        services,
        portfolio,
        statistics,
        news,
        contactme
      },
    });
  }

  
}



export default new PageService();
