import React from 'react';
import { Counter } from './features/counter/Counter';
import { createBrowserRouter } from 'react-router-dom';
import AppLayout from './components/common/appLayout';
import Index from './components';
import InfoProject from './components/infoProject';
import Error404 from './components/error/error404';

const element = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        path: "*",
        element: <Error404 />
      },
      {
        index: true,
        path: "",
        element: <Index />,
      },
      {
        path: "project/:projectid",
        element: <InfoProject />,
      },
    ]
  },


])

export default element

