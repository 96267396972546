import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import portfolioService from "../../api/page/portfolio/portfolio.service";
import Age from "../../assets/landing/img/svg/calendar-4.svg";
import Location from "../../assets/landing/img/svg/location-1.svg";
import Interests from "../../assets/landing/img/svg/game-controller-2.svg";
import Study from "../../assets/landing/img/svg/study-5.svg";
import BorderLayer from "../../assets/landing/img/ratios/550x650.jpg";
import Project404 from "../error/project404";
import LoadingImage from "../animations/loadingImage";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

const ProjectInfo = ({ }) => {
    const { projectid } = useParams()
    const [project, setProject] = useState<any>([])
    const [preview, setPreview] = useState<any>()
    const [error,setError] = useState<boolean>(false)
    const [pass,setPass] = useState<boolean>(false)
    const { t, i18n } = useTranslation();
    const re = /^[0-9\b]+$/;


    useEffect(() => {
        console.log()
        if(projectid?.match(re)){
            getProject(projectid)
        }else{
            setError(true)
        }
       
    }, [null]);


    async function getProject(projectid) {

        portfolioService.find(
            projectid
        ).then(
            response => {

                setProject(response.data)

                ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: `project info: ${response.data.project_name}` });

            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                    if(resMessage == "project-not-found"){
                        setError(true)
                    } 

            }

        );

        portfolioService.getImage(
            projectid
        ).then(
            response => {

                const reader = new FileReader()
                reader.readAsDataURL(response.data)
                reader.onload = () => {
                    setPreview(reader.result)
                }

                setPass(true)

            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

            }

        );


    }

    return (
        <>
         {!error ? 
            <div className="arlo_tm_about" style={{ marginBottom: "600px" }}>
                <div className="container">
                    <div className="arlo_tm_main_title">
                        <span>{t('info_project.section')}</span>
                        <h3>{t('info_project.title')}</h3>
                    </div>
                    <div className="about_inner">
                        <div className="left">
                            <div
                                className="about_image_wrap parallax"
                                data-relative-input="true"
                            >
                                <div className="image layer" data-depth="0.1">
                                    <img src={BorderLayer} alt="" />
                                   
                                    <LoadingImage preview={preview} className={'inner'} style={{ background: "#ccc" }}  padding={"1.25rem"} initialHeight={'32rem'} imageLoad={pass ? false : null} pulse={pass ? false : null}/>
                                    {/*<div className="inner" data-img-url={AboutImg} style={{ backgroundImage: `url(${preview})` }}></div>*/}
                                </div>
                                <div className="border layer" data-depth="0.2">
                                    <img src={BorderLayer} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="right">
                            <div className="about_title">
                                <h3>
                                    {project.project_name}
                                </h3>
                            </div>
                            <div className="text">
                                <p>
                                    {project.description}
                                </p>
                            </div>
                            <div className="about_short_contact_wrap">
                                <ul>
                                    <li>
                                        <img className="svg" src={Study} alt="" />
                                        <span>
                                            <label>{t('info_project.customer')}</label>{" "}
                                            <a className="href_location" href="">
                                                {" "}
                                                {project.customer}
                                            </a>
                                        </span>
                                    </li>
                                    <li>
                                        <img className="svg" src={Interests} alt="" />
                                        <span>
                                            <label>{t('info_project.category')}</label> {project.category}
                                        </span>
                                    </li>
                                    <li>
                                        <img className="svg" src={Age} alt="" />
                                        <span>
                                            <label>{t('info_project.date')}</label> {project.date}
                                        </span>
                                    </li>
                                    {project.locationinfo ? <li>
                                        <img className="svg" src={Location} alt="" />
                                        <span>
                                            <label>{t('info_project.location')}</label>{" "}
                                            <a className="href_location" href="">
                                                {project.location}
                                            </a>
                                        </span>
                                    </li> : null}
                                </ul>
                            </div>
                            <div className="arlo_tm_button">
                                <a href={project.website} target="_blank" onClick={() => [ReactGA.event({category: "project visit website",action: `project visit website: ${project.project_name}`})]}>
                                    <span>{t('info_project.go_button')}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         : <Project404/>}
        </>
    )
}

export default ProjectInfo

