import React, { useEffect, useState } from "react";
import Ratios from '../../assets/landing/img/ratios/ratio-4-3.jpg'
import PortfolioImg from "../../assets/landing/img/portfolio/1.jpg"
import portfolioService from "../../api/page/portfolio/portfolio.service";


const ProjectImage = ({ id }) => {
    const [preview, setPreview] = useState<any>()
    

    useEffect(() => {

        portfolioService.getImage(
            id
        ).then(
            response => {

                const reader = new FileReader()
                reader.readAsDataURL(response.data)
                reader.onload = () => {
                    setPreview(reader.result)
                }

            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

            }

        );

    }, [id]);






    return (
        <>
            <a className="zoom" href={`./project/${id}`}>
                <img src={PortfolioImg} alt="" />
                <div className="main_image" data-img-url="img/portfolio/1.jpg" style={{ backgroundImage: `url(${preview})` }}></div>
            </a>
        </>


    )
}

export default ProjectImage