import React from "react";
import { Component } from "react";
import newsService from "../api/news/news.service";
import NewsImagePreview from "./elements/newsImagePreview";
import ModalNewsContent from "./elements/modalNewsContent";
import { WithTranslation, withTranslation } from "react-i18next";

interface Props extends WithTranslation {
 
}

type State = {
	modal: boolean,
	news: any[],
	newsInfo: {
		id: number,
		title: string,
		author: string,
		date: string,
		text: string,
		highlight: boolean,
		texthighlighted: string,
		sharing: boolean
	}
};

 class News extends Component<Props, State> {

	constructor(props: Props) {
		super(props);


		this.state = {
			modal: false,
			news: [],
			newsInfo: {
				id: 0,
				title: '',
				author: '',
				date: '',
				text: '',
				highlight: false,
				texthighlighted: '',
				sharing: false
			}
		};
	}

	setModal = (state) => {
		this.checkStatus()
		this.setState({
			modal: state
		})
	}

	checkStatus = () => {
		if (this.state.modal)
			this.setState({
				modal: false
			})
	}

	setNewsInfo = (news) => {
		this.setState({
			newsInfo: {
				id: news.id,
				title: news.title,
				author: news.author,
				date: news.date,
				text: news.text,
				highlight: news.highlight,
				texthighlighted: news.texthighlighted,
				sharing: news.sharing
			}
		})
	}


	getAllNews = async () => {

		newsService.getNews(
		).then(
			response => {

				this.setState({ news: response.data })


			},
			error => {
				const resMessage =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();


			}

		);
	}

	componentDidMount() {
		this.getAllNews()
	}


	render() {

		const { } = this.state;

		const { t } = this.props


		return (

			<>
				<div className={this.state.modal ? "arlo_tm_modalbox_news opened" : "arlo_tm_modalbox_news"}>
					<div className="box_inner">
						<div>
							<div className="close" onClick={() => this.setModal(false)}>
								<a>
									<svg
										onClick={() => this.setModal(false)}
										xmlns="http://www.w3.org/2000/svg"
										xlinkHref="http://www.w3.org/1999/xlink"
										version="1.1"
										id="Capa_1"
										x="0px"
										y="0px"
										viewBox="0 0 511.995 511.995"
										style={{ background: ' new 0 0 511.995 511.995' }}
										xmlSpace="preserve"
										className="svg replaced-svg"
									>
										<g>
											<g>
												<path d="M437.126,74.939c-99.826-99.826-262.307-99.826-362.133,0C26.637,123.314,0,187.617,0,256.005    s26.637,132.691,74.993,181.047c49.923,49.923,115.495,74.874,181.066,74.874s131.144-24.951,181.066-74.874    C536.951,337.226,536.951,174.784,437.126,74.939z M409.08,409.006c-84.375,84.375-221.667,84.375-306.042,0    c-40.858-40.858-63.37-95.204-63.37-153.001s22.512-112.143,63.37-153.021c84.375-84.375,221.667-84.355,306.042,0    C493.435,187.359,493.435,324.651,409.08,409.006z"></path>
											</g>
										</g>
										<g>
											<g>
												<path d="M341.525,310.827l-56.151-56.071l56.151-56.071c7.735-7.735,7.735-20.29,0.02-28.046    c-7.755-7.775-20.31-7.755-28.065-0.02l-56.19,56.111l-56.19-56.111c-7.755-7.735-20.31-7.755-28.065,0.02    c-7.735,7.755-7.735,20.31,0.02,28.046l56.151,56.071l-56.151,56.071c-7.755,7.735-7.755,20.29-0.02,28.046    c3.868,3.887,8.965,5.811,14.043,5.811s10.155-1.944,14.023-5.792l56.19-56.111l56.19,56.111    c3.868,3.868,8.945,5.792,14.023,5.792c5.078,0,10.175-1.944,14.043-5.811C349.28,331.117,349.28,318.562,341.525,310.827z"></path>
											</g>
										</g>
										<g></g>
										<g></g>
										<g></g>
										<g></g>
										<g></g>
										<g></g>
										<g></g>
										<g></g>
										<g></g>
										<g></g>
										<g></g>
										<g></g>
										<g></g>
										<g></g>
										<g></g>
									</svg>
								</a>
							</div>
						</div>
						<div className="description_wrap" ><ModalNewsContent id={this.state.newsInfo.id} title={this.state.newsInfo.title} author={this.state.newsInfo.author} date={this.state.newsInfo.date} text={this.state.newsInfo.text} highlight={this.state.newsInfo.highlight} texthighlighted={this.state.newsInfo.texthighlighted} sharing={this.state.newsInfo.sharing} /></div>
					</div>
				</div>


				<div className="arlo_tm_section" id="news">
					<div className="arlo_tm_news">
						<div className="container">
							<div className="arlo_tm_main_title">
								<span>{t('news.section')}</span>
								<h3>{t('news.title')}</h3>
							</div>
							<div className="news_list">
								{this.state.news.length == 0 ? <p style={{ display: "inline-block" }}>{t('news.no_content')}</p> :
									<>
										<ul>
											{this.state.news?.map((item, index) => {
												return (

													<li className="wow fadeInLeft" data-wow-duration="1.5s" onClick={() => [this.setModal(true), this.setNewsInfo(item)]} key={index}>
														<div className="list_inner">
															<NewsImagePreview id={item.id} />
															<div className="details">
																<h3 className="title"><a >{item.title}</a></h3>
																<p className="date">By <a >{item.author}</a> <span>{item.date}</span></p>
															</div>
															<div className="description">
																<p>{item.text}</p>
																{item.hightlight ? <blockquote>{item.texthighlighted}</blockquote> : null}
																{item.sharing ? <div className="share">
																	<span>Share:</span>
																	<ul className="social">
																		<li><a href="#"><img className="svg" src="img/svg/social/facebook.svg" alt="" /></a></li>
																		<li><a href="#"><img className="svg" src="img/svg/social/twitter.svg" alt="" /></a></li>
																		<li><a href="#"><img className="svg" src="img/svg/social/behance.svg" alt="" /></a></li>
																		<li><a href="#"><img className="svg" src="img/svg/social/dribbble.svg" alt="" /></a></li>
																	</ul>
																</div> : null}
															</div>
														</div>
													</li>

												)
											})}

										</ul>
									</>}
							</div>
						</div>
					</div>
				</div>

			</>

		)
	}
}

export default withTranslation()(News)