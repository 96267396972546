import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import commonEn from './locales/en/translation.json'
import commonIt from './locales/it/translation.json'


const resources = {
  it: { common: commonIt},
  en: { common: commonEn },

}

const options = {
  order: ['querystring', 'navigator'],
  lookupQuerystring: 'lng'
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: options,
    resources,
    ns: ['common'],
    defaultNS: 'common',
    fallbackLng: 'en',
    //supportedLngs: ['it', 'en'],
    interpolation: {
      escapeValue: false,
    },
    debug: false,
  });


export default i18n;

