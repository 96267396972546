import axios from "axios";
import { api } from "../../settingApi"

const VERSION = "v1";

class PortfolioService {
  findAll() {
    return api({
      method: "get",
      url: `${VERSION}/page/portfolio/project`,
    });
  }

  find(id) {
    return api({
      method: "get",
      url: `${VERSION}/page/portfolio/project/${id}`,
    });
  }

  getImage(id: number) {
    return api({
      method: "get",
      url: `${VERSION}/page/portfolio/project/image/${id}`,
      responseType: "blob",
    });
  }

  create(
    project_name: string,
    description: string,
    customer: string,
    category: string,
    date: string,
    website: string,
    locationinfo: boolean,
    location: string
  ) {
    return api({
      method: "post",
      url: `${VERSION}/page/portfolio/project`,
      data: {
        project_name,
        description,
        customer,
        category,
        date,
        website,
        locationinfo,
        location,
      },
    });
  }

  createImage(image, id) {
    const formData = new FormData();
    formData.append("file", image);

    if (id == undefined) {
      id = "";
    }

    return api({
      method: "post",
      url: `${VERSION}/page/portfolio/project/image/${id}`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(
    id: number,
    description: string,
    customer: string,
    project_name: string,
    category: string,
    date: string,
    website: string,
    locationinfo: boolean,
    location: string
  ) {
    return api({
      method: "patch",
      url: `${VERSION}/page/portfolio/project/${id}`,
      data: {
        project_name,
        description,
        customer,
        category,
        date,
        website,
        locationinfo,
        location,
      },
    });
  }

  updateImage(image, id) {
    const formData = new FormData();
    formData.append("file", image);

    if (id == undefined) {
      id = "";
    }

    return api({
      method: "patch",
      url: `${VERSION}/page/portfolio/project/image/update/${id}`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id: number) {
    return api({
      method: "delete",
      url: `${VERSION}/page/portfolio/project/${id}`,
    });
  }
}

export default new PortfolioService();
