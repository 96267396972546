import React from "react";
import { Component } from "react";
import pageService from "../api/page/page.service";
import { Navigate, NavigationType } from "react-router-dom";
import ProjectInfo from "./elements/infoProject";

type Props = {};

type State = {
  message: string;
  successful: boolean;
};


export default class InfoProject extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    

    this.state = {
      message: "",
      successful: false,
    };
  
  }

  

  componentDidMount(): void {
  }

  render(){

    const { } = this.state;


    return (

     <>
      <ProjectInfo/>
     </>

    )
  }
}