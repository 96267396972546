import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ActiveLink from "./activeLink";
import pageService from "../../../api/page/page.service";
import { useTranslation } from "react-i18next";

const Navbars = ({ }) => {

    const location = useLocation();
    const [open, setOpen] = useState<boolean>(false)
    const [settings, setSettings] = useState<any[]>()
    const { t, i18n } = useTranslation();

    useEffect(() => {
        getSettings()
    }, [null]);

    function openMobileMenù() {
        setOpen(!open)
    }

    function getSettings() {
        pageService.getSettings().then(
            (response) => {

                if (response.data[0]) {
                    setSettings(response.data)
                }

            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );
    }

    const cssActive: React.CSSProperties = {
        display: 'block'
    }

    const cssDeActive: React.CSSProperties = {
        display: 'none'
    }



    return (

        <>

            {location.pathname.includes('project/') ?


                <div className="arlo_tm_mobile_menu">
                    <div className="mobile_menu_inner">
                        <div className="container">
                            <div className="mobile_in">
                                <div className="logo">
                                    <a href="#"><img src="img/logo/mobile/dark.png" alt="" /></a>
                                </div>
                                <div className="my_trigger">
                                    <div className={open ? "hamburger hamburger--collapse-r is-active" : "hamburger hamburger--collapse-r"}>
                                        <div className="hamburger-box" onClick={openMobileMenù}>
                                            <div className="hamburger-inner"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dropdown" style={open ? cssActive : cssDeActive}>
                        <div className="container">
                            <div className="dropdown_inner">
                                <ul className="anchor_nav">
                                    <li><a href="../../">{t('navbar.home')}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                :

                <div className="arlo_tm_mobile_menu">
                    <div className="mobile_menu_inner">
                        <div className="container">
                            <div className="mobile_in">
                                <div className="logo">
                                    <a href="#"><img src="img/logo/mobile/dark.png" alt="" /></a>
                                </div>
                                <div className="my_trigger">
                                    <div className={open ? "hamburger hamburger--collapse-r is-active" : "hamburger hamburger--collapse-r"}>
                                        <div className="hamburger-box" onClick={openMobileMenù}>
                                            <div className="hamburger-inner"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dropdown" style={open ? cssActive : cssDeActive}>
                        <div className="container">
                            <div className="dropdown_inner">
                                <ul className="anchor_nav">
                                    <li><a href="#home">{t('navbar.home')}</a></li>
                                    <li><a href="#about">{t('navbar.about')}</a></li>
                                    <li><a href="#services">{t('navbar.services')}</a></li>
                                    <li><a href="#portfolio">{t('navbar.portfolio')}</a></li>
                                    <li><a href="#news">{t('navbar.news')}</a></li>
                                    <li><a href="#contact">{t('navbar.contact')}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>}

            {location.pathname.includes('project/') ?

                <div className="arlo_tm_topbar animate">
                    <div className="container">
                        <div className="topbar_inner">
                            <div className="logo">
                                <a href="#"><img className="light" src="img/logo/classic/light.png" alt="" /></a>
                                <a href="#"><img className="dark" src="img/logo/classic/dark.png" alt="" /></a>
                            </div>
                            <div className="menu">
                                <ul className="anchor_nav">
                                    <li><a href="../../">{t('navbar.home')}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="arlo_tm_topbar">
                    <div className="container">
                        <div className="topbar_inner">
                            <div className="logo">
                                <a href="#"><img className="light" src="img/logo/classic/light.png" alt="" /></a>
                                <a href="#"><img className="dark" src="img/logo/classic/dark.png" alt="" /></a>
                            </div>
                            <div className="menu">
                                <ul className="anchor_nav">
                                    {settings?.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                {item.home ? <ActiveLink children={<a href="#home">{t('navbar.home')}</a>} path={'#home'} /> : null}
                                                {item.aboutme ? <ActiveLink children={<a href="#about">{t('navbar.about')}</a>} path={'#about'} /> : null}
                                                {item.services ? <ActiveLink children={<a href="#services">{t('navbar.services')}</a>} path={'#services'} /> : null}
                                                {item.portfolio ? <ActiveLink children={<a href="#portfolio">{t('navbar.portfolio')}</a>} path={'#portfolio'} /> : null}
                                                {item.news ? <ActiveLink children={<a href="#news">{t('navbar.news')}</a>} path={'#news'} /> : null}
                                                {item.contactme ? <ActiveLink children={<a href="#contact">{t('navbar.contact')}</a>} path={'#contact'} /> : null}
                                            </div>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>}


        </>
    )
}

export default Navbars