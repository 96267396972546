import "../../assets/landing/css/loadingImg.css"
import { useState } from "react";
import { motion } from "framer-motion";

const LoadingImage = ({ preview, className, style, padding , initialHeight, imageLoad, pulse }) => {
    const [imageLoading, setImageLoading] = useState(true);
    const [pulsing, setPulsing] = useState(true);

    const imageLoaded = () => {
        setImageLoading(imageLoad);
        setTimeout(() => setPulsing(pulse));
    };

    return (
        <div className={className}>
            <div className="App" style={{  padding: padding}}>
            <div
                className={`${pulsing ? "pulse" : ""} loadable`}
                style={style}
            >
                <motion.img
                    initial={{ height: initialHeight, opacity: 0 }}
                    animate={{
                        height: imageLoading ? "16rem" : 'auto',
                        opacity: imageLoading ? 0 : 1
                    }}
                    transition={{
                        height: { delay: 0, duration: 0.4 },
                        opacity: { delay: 0.5, duration: 0.4 }
                    }}
                    onLoad={imageLoaded}
                    src={preview}
                    className="imgload"
                    alt=""
                >

                </motion.img>
            </div>
        </div>
        </div >
    );
}
export default LoadingImage