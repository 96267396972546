import React from "react";
import { Component } from "react";
import statisticsService from "../api/statistics/statistics.service";
import { toHaveStyle } from "@testing-library/jest-dom/matchers";
import { WithTranslation, withTranslation } from "react-i18next";

interface Props extends WithTranslation {
 
}


type State = {
  message: string;
  successful: boolean;
  updateid: number;
  loading: boolean;
  customers: string;
  projects: string;
  ongoing: string;
  experience: string;
};

 class Counter extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      message: "",
      successful: false,
      updateid: 1,
      loading: true,
      customers: "",
      projects: "",
      ongoing: "",
      experience: "",
    };
  }

  getSettings = async () => {
    statisticsService.getSettings().then(
      (response) => {
        if (response.data[0]) {
          this.setState({
            customers: response.data[0].customers,
            projects: response.data[0].projects,
            ongoing: response.data[0].ongoing,
            experience: response.data[0].experience,
          });
        }

        setTimeout(() => {
          this.setState({
            loading: false,
          });
        }, 500);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  };

  componentDidMount(){
	this.getSettings()
  }

  render() {
    const {} = this.state;

    const { t } = this.props

    return (
      <>
        <div className="arlo_tm_section">
          <div className="arlo_tm_counter_section">
            <div className="container">
              <div className="counter_inner">
                <ul className="wow fadeInLeft" data-wow-duration="1.5s">
                  <li>
                    <div className="list_inner">
                      <h3
                        className="arlo_tm_counter"
                        data-from="0"
                        data-to="555"
                        data-speed="2000"
                      >
                        {this.state.customers}
                      </h3>
                      <span>{this.state.customers > '1' ? t('counter.happy_clients') : t('counter.happy_client')}</span>
                    </div>
                  </li>
                  <li>
                    <div className="list_inner">
                      <h3
                        className="arlo_tm_counter"
                        data-from="0"
                        data-to="222"
                        data-speed="2000"
                      >
                        {this.state.projects}
                      </h3>
                      <span>{this.state.projects > '1' ? t('counter.projects_finished') : t('counter.project_finished')}</span>
                    </div>
                  </li>
                  <li>
                    <div className="list_inner">
                      <h3
                        className="arlo_tm_counter"
                        data-from="0"
                        data-to="444"
                        data-speed="2000"
                      >
                        {this.state.ongoing}
                      </h3>
                      <span>{t('counter.in_progress') }</span>
                    </div>
                  </li>
                  <li>
                    <div className="list_inner">
                      <h3
                        className="arlo_tm_counter"
                        data-from="0"
                        data-to="777"
                        data-speed="2000"
                      >
                        {this.state.experience}
                      </h3>
                      <span>{this.state.experience > '1' ? t('counter.years_experience')  : t('counter.year_experience') }</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(Counter)